<template>
  <router-view></router-view>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped></style>
